import React, { Fragment } from 'react';

interface Props {
  data: AddressColumnProps;
}
const AddressColumn = ({ data: { title, address, phone, email } }: Props) => (
  <Fragment>
    <h2>{title}</h2>
    <div className="contact-info-box address clearfix">
      <h3>
        <i className="fa fa-map-marker" />
        {address.label}:
      </h3>
      <span>{address.value}</span>
    </div>
    {phone && phone.value && (
      <div className="contact-info-box phone clearfix">
        <h3>
          <i className="fa fa-phone" />
          {phone.label}:
        </h3>
        <span>
          <a href="tel:{{site.phone}}">{phone.value}</a>
        </span>
      </div>
    )}
    <div className="contact-info-box email clearfix">
      <h3>
        <i className="fa fa-pencil" />
        {email.label}:
      </h3>
      <span>
        <a href="mailto:{{site.email}}">{email.value}</a>
      </span>
    </div>
  </Fragment>
);

export default AddressColumn;
