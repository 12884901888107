import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';

import { formatMarkdownData } from '../data/_helper';

import Layout from '../components/Layout';
import Slider from '../components/Slider';
import Navigation from '../components/Navigation';
import About from '../components/About';
import Products from '../components/Products';
import Distributor from '../components/Distributor';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

interface IndexPageProps {
  slider: SliderProps;
  navigation: NavigationProps;
  about_section: AboutProps;
  products_section: ProductsProps;
  distributor_section: DistributorProps;
  contact_us_section: ContactUsProps;
  footer_section: FooterProps;
}
const IndexPageTemplate = ({
  slider,
  navigation,
  about_section,
  products_section,
  distributor_section,
  contact_us_section,
  footer_section
}: IndexPageProps) => (
  <Layout>
    <Slider data={slider} />
    <Navigation data={navigation} />

    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => <About data={about_section} isVisible={isVisible} />}
    </VisibilitySensor>

    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => <Products data={products_section} isVisible={isVisible} />}
    </VisibilitySensor>

    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => <ContactUs data={contact_us_section} isVisible={isVisible} />}
    </VisibilitySensor>

    <Footer data={footer_section} />
  </Layout>
);

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexPageQuery {
        allMarkdownRemark(limit: 1, filter: { frontmatter: { page: { eq: "index" } } }) {
          edges {
            node {
              id
              frontmatter {
                slider {
                  sliderLogo
                  sliderLogoText
                  sliderTitle
                }
                navigation {
                  about
                  contacts
                  products
                  distributor
                  logo {
                    file
                    text
                  }
                }
                about_section {
                  title
                  subtitle
                  image {
                    file
                    text
                  }
                  options {
                    title
                    text
                    icon
                  }
                }
                products_section {
                  title
                  subtitle
                  product_list {
                    title
                    image
                    pdf_file
                  }
                }
                distributor_section {
                  title
                  subtitle
                  image {
                    file
                    text
                  }
                  company {
                    label
                    value
                    url
                  }
                }
                contact_us_section {
                  title
                  address_column {
                    title
                    address {
                      label
                      value
                    }
                    phone {
                      label
                      value
                    }
                    email {
                      label
                      value
                    }
                  }
                  form_column {
                    thanks
                    title
                    subtitle
                    name_placeholder
                    email_placeholder
                    subject_placeholder
                    message_placeholder
                    button_text
                  }
                }
                footer_section {
                  privacy
                  copyright
                  logo {
                    file
                    text
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const FormData = formatMarkdownData<IndexPageProps>(data)[0];
      const {
        slider,
        navigation,
        about_section,
        products_section,
        distributor_section,
        contact_us_section,
        footer_section
      } = FormData;
      return (
        <IndexPageTemplate
          slider={slider}
          navigation={navigation}
          about_section={about_section}
          products_section={products_section}
          distributor_section={distributor_section}
          contact_us_section={contact_us_section}
          footer_section={footer_section}
        />
      );
    }}
  />
);

export default IndexPage;
