import React from 'react';

const closeMenu = () => {
  const navElement = document.getElementById('main-nav');
  if (navElement && navElement.classList.contains('animated')) {
    navElement.style.display = 'none';
    navElement.classList.remove('animated');
    navElement.classList.remove('fadeInDown');
  }
};

const openMenu = () => {
  const navElement = document.getElementById('main-nav');
  if (navElement) {
    if (navElement.classList.contains('animated')) {
      closeMenu();
      return;
    }
    navElement.style.display = 'block';
    navElement.classList.add('animated');
    navElement.classList.add('fadeInDown');
  }
};

const scrollTo = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();

  const { section } = e.currentTarget.dataset;
  if (section) {
    const element = document.getElementById(section);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 100,
        behavior: 'smooth'
      });
    }
  }
  closeMenu();
};

const showMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
  openMenu();
};

interface Props {
  data: NavigationProps;
}
const Navigation = ({ data: { about, products, distributor, contacts, logo } }: Props) => (
  <nav className="main-nav-outer sticky animated fadeIn delay-04s">
    <div className="container clearfix">
      <ul className="main-nav" id="main-nav">
        <li>
          <a onClick={scrollTo} href="#about" data-section="about">
            {about}
          </a>
        </li>
        <li>
          <a onClick={scrollTo} href="#products" data-section="products">
            {products}
          </a>
        </li>
        <li className="small-logo">
          <a onClick={scrollTo} href="#home" data-section="home">
            <img src={logo.file} alt={logo.text} />
          </a>
        </li>
        <li>
          <a onClick={scrollTo} href="#contacts" data-section="contacts">
            {contacts}
          </a>
        </li>
      </ul>

      <a className="res-nav_click" href="#" onClick={showMenu}>
        <i className="fa fa-bars" />
      </a>
    </div>
  </nav>
);

export default Navigation;
