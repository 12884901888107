import React from 'react';

interface Props {
  thanksMessage: string;
}
const ThanksMessage = ({ thanksMessage }: Props) => (
  <div className="text-center text-primary thanks">
    <h2>{thanksMessage}</h2>
  </div>
);
export default ThanksMessage;
