import React, { FormEvent } from 'react';

interface Props {
  data: FormColumnProps;
  formAction: (e: FormEvent<HTMLFormElement>) => void;
}
const FormColumn = ({
  formAction,
  data: { title, subtitle, name_placeholder, subject_placeholder, email_placeholder, message_placeholder, button_text }
}: Props) => (
  <div className="form">
    <h2>{title}</h2>
    <form className="contactForm" onSubmit={formAction}>
      <h3>{subtitle}</h3>
      <div className="form-group">
        <input
          type="text"
          name="name"
          required={true}
          className="form-control input-text"
          placeholder={name_placeholder}
        />
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          required={true}
          className="form-control input-text"
          placeholder={email_placeholder}
        />
        <div className="validation" />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="subject"
          required={true}
          className="form-control input-text"
          placeholder={subject_placeholder}
        />
      </div>
      <div className="form-group">
        <textarea
          name="message"
          rows={5}
          className="form-control input-text text-area"
          placeholder={message_placeholder}
        />
        <div className="validation" />
      </div>

      <div className="text-center">
        <button type="submit" className="input-btn">
          {button_text}
        </button>
      </div>
    </form>
  </div>
);

export default FormColumn;
