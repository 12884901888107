import React, { Component } from 'react';

import AddressColumn from './Address';
import FormColumn from './Form';
import ThanksMessage from './ThanksMessage';

interface Props {
  data: ContactUsProps;
  isVisible: boolean | null;
}
interface State {
  sent: boolean;
}
class ContactUs extends Component<Props, State> {
  state = {
    sent: false
  };

  formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    fetch('https://httpbin.org/post', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        console.log(response);
        if (response.ok) {
          this.setState({ sent: true });
        }
      })
      .catch(console.log);
  };

  render() {
    const { title, address_column, form_column } = this.props.data;
    const { isVisible } = this.props;

    return (
      <div className="clearfix">
        <section className="business-talking">
          <div className="container">
            <h2 className={`${isVisible ? 'animated fadeIn delay-03s' : 'invisible'}`}>{title}</h2>
          </div>
        </section>
        <div className="container">
          <section className="main-section contact" id="contacts">
            <div className="row">
              <div className={`col-lg-6 col-sm-7 ${isVisible ? 'animated fadeIn delay-04s' : 'invisible'}`}>
                <AddressColumn data={address_column} />
              </div>
              <div className={`col-lg-6 col-sm-5 ${isVisible ? 'animated fadeIn delay-05s' : 'invisible'}`}>
                {this.state.sent ? (
                  <ThanksMessage thanksMessage={form_column.thanks} />
                ) : (
                  <FormColumn data={form_column} formAction={this.formSubmit} />
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ContactUs;
