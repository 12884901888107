import React from 'react';

interface Props {
  data: ProductsProps;
  isVisible: boolean | null;
}
const Products = ({ isVisible, data: { title, subtitle, product_list } }: Props) => (
  <section className="main-section padding" id="products">
    <div className={`container ${isVisible ? 'animated fadeInUp delay-03s' : 'invisible'}`}>
      <h2>{title}</h2>
      <h6>{subtitle}</h6>
    </div>
    <div className="container">
      <div className="row products-container">
        {product_list.map((item, index) => (
          <div
            key={index}
            className={`col-sm-4 col-xs-12 products-box ${isVisible ? 'animated fadeInUp delay-06s' : 'invisible'}`}
          >
            <a target="_blank" rel="noopener noreferrer" href={item.pdf_file}>
              <img src={item.image} alt={item.title} />
            </a>
            <h3>{item.title}</h3>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Products;
