import React from 'react';

interface Props {
  data: AboutProps;
  isVisible: boolean | null;
}
const About = ({ isVisible, data: { title, subtitle, options, image } }: Props) => (
  <section className="main-section" id="about">
    <div className="container">
      <div className={`mt8 mb16 ${isVisible ? 'animated fadeInDown delay-06s' : 'invisible'}`}>
        <h2>{title}</h2>
        {subtitle && <h6>{subtitle}</h6>}
      </div>
      <div className="row">
        <div className={`col-sm-7 ${isVisible ? 'animated fadeIn delay-07s' : 'invisible'}`}>
          {options.map((item, index) => (
            <div key={index} className="service-list">
              <div className="service-list-col1">
                <i className={`fa fa-${item.icon}`} />
              </div>
              <div className="service-list-col2">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <figure className={`col-sm-5 text-right ${isVisible ? 'animated fadeIn delay-08s' : 'invisible'}`}>
          {image && <img src={image.file} alt={image.text} />}
        </figure>
      </div>
    </div>
  </section>
);

export default About;
