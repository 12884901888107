import React from 'react';
import { Link } from 'gatsby';

interface Props {
  data: SliderProps;
}
const Slider = ({ data: { sliderLogo, sliderLogoText, sliderTitle } }: Props) => (
  <header className="header" id="home">
    <div className="mask" />
    <div className="container">
      <figure className="logo animated fadeInDown delay-01s">
        <Link to="/">
          <img src={sliderLogo} alt={sliderLogoText} />
        </Link>
      </figure>
      <h1 className="animated fadeInUp delay-02s">{sliderTitle}</h1>
    </div>
  </header>
);

export default Slider;
